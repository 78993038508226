/*
 * Sets up the select element in the donation form so that the selected purpose is
 * reflected in the description below and the submit button
 */
var PurposeSelector = (function(){

  function handle_purpose_change( form ) {

    var select = form.querySelector( '.cta-box__purpose' );
    var description = form.querySelector( '.cta-box__category-description' );
    var button = form.querySelector( '.cta-box__submit-caption' );


    select.addEventListener( 'change', function(){

      var option = this.options[ this.selectedIndex ];

      var desc = option.getAttribute( "data-description" );
      var name = option.innerHTML;

      description.innerHTML = desc;
      button.innerHTML = name;

    });

    // Add initial text
    var event = new Event('change');
    select.dispatchEvent(event);

  }

  function setup( selector ){

    document.addEventListener('DOMContentLoaded', function() {
      var forms = document.querySelectorAll( selector );

      for ( var i = 0, j = forms.length; i < j; ++i) {
        handle_purpose_change( forms[i] );
      }
    });

  }

  var module = {};
  module.setup = setup;
  return module;
})()

var CustomSelector = (function(){

  function handle_value_change( select ) {
    select.addEventListener( 'change', function(){

      if( select.value !== 'custom' ) {
        return;
      }

      var input = document.createElement('input');

      input.setAttribute( 'name', select.getAttribute('name') );
      input.setAttribute( 'aria-label', select.getAttribute('aria-label') );
      input.setAttribute( 'placeholder', '0' );
      input.setAttribute( 'class',
          'form-field__control form-field__control--xsmall' );
      input.setAttribute( 'type', 'text' );
      input.setAttribute( 'pattern', '[0-9]*' );
      input.setAttribute( 'required', '' );
      input.setAttribute( 'id', select.id + '-custom' );
      if (!select.parentNode.parentNode.querySelector('.js-donation-type') &&
          'customAmountMin' in select.dataset) {
        input.setAttribute('data-min', select.dataset.customAmountMin);
      }

      var parent = select.parentNode;
      var grandparent = parent.parentNode;
      var prefix = select.getAttribute('data-custom-amount-prefix');
      grandparent.replaceChild(input, parent);
      grandparent.insertBefore(document.createTextNode(prefix), input);
      /* IE does not support Element.closest() */
      var formFieldWrapper = grandparent;
      if (formFieldWrapper.className.indexOf('form-field') < 0) {
        formFieldWrapper = formFieldWrapper.parentNode;
      }
      ValidateField.setupWrapper(formFieldWrapper);
      input.focus();

    });
  }

  function setup( selector ){
    document.addEventListener('DOMContentLoaded', function() {
      var selects = document.querySelectorAll( selector );

      for ( var i = 0, j = selects.length; i < j; ++i) {
        handle_value_change( selects[i] );
        selects[i].querySelector('[value=custom]').removeAttribute('hidden');
      }
    });
  }

  var module = {};
  module.setup = setup;
  return module;
})();

/* Enable switching between "gift" and "guardian" donation types
 *
 * Input fields inside the specified gift-only/guardian-only sections are
 * hidden and disabled when not matching the selected type. This functionality
 * depends on no other fields being disabled for other reasons. (And it only
 * works for input elements, not e.g. textarea or select.)
 *
 * Additionally, donation amount options are switched out to match the donation
 * type, and a hidden input is updated so that the form will submit correct
 * project/product code.
 */
var DonationTypeSelector = (function(){

  function handle_value_change( select ) {
    select.addEventListener( 'change', function(){
      set_type_input( select );
      change_amounts( select );
      toggle_fields( select );
      toggle_minimum( select );
      toggle_submit_text( select );
    });
    toggle_fields( select );
    toggle_minimum( select );
  }

  function set_type_input( select ) {
    var typeInputId = select.getAttribute('data-type-input');
    var typeInputElement = document.getElementById(typeInputId);

    var newValue = select.getAttribute('data-product-code-' + select.value);
    typeInputElement.value = newValue;
  }

  function change_amounts( typeSelect ) {
    var selectedType = typeSelect[typeSelect.selectedIndex];
    var newAmounts = selectedType && selectedType.getAttribute('data-amounts');
    newAmounts = JSON.parse(newAmounts);

    var amountSelect =
        document.getElementById(typeSelect.getAttribute('data-amount-select'));
    if (!amountSelect) {
      return;
    }

    while (amountSelect[1]) {
      /* Keep the last one, which is the "custom" option */
      amountSelect.remove(0);
    }

    var amountFormat = amountSelect.getAttribute('data-option-format');
    var defaultIndex = 0;
    var customOption = amountSelect[0];
    for (var i = 0; i < newAmounts.length; i++) {
      var amount = newAmounts[i];
      var option = document.createElement('option');
      option.value = amount.amount;
      option.textContent = amountFormat.replace('{n}', amount.amount);
      amountSelect.insertBefore(option, customOption);
      if (amount.default && !defaultIndex) {
        defaultIndex = i;
      }
    }
    amountSelect.selectedIndex = defaultIndex;
  }

  function toggle_fields( typeSelect ) {
    var form = document.getElementById(typeSelect.getAttribute('data-form'));
    var selectedType = typeSelect.value;
    var otherType = (selectedType === 'gave' ? 'giver' : 'gave');

    var showSelector = '[data-donation-type=' + selectedType + ']';
    var hideSelector = '[data-donation-type=' + otherType + ']';
    var sectionsToShow = form.querySelectorAll(showSelector);
    var sectionsToHide = form.querySelectorAll(hideSelector);
    var inputsToEnable = form.querySelectorAll(showSelector + ' input');
    var inputsToDisable = form.querySelectorAll(hideSelector + ' input');

    for (var i = 0; i < sectionsToShow.length; i++) {
      sectionsToShow[i].removeAttribute('hidden');
    }
    for (i = 0; i < sectionsToHide.length; i++) {
      sectionsToHide[i].setAttribute('hidden', '');
    }
    for (i = 0; i < inputsToEnable.length; i++) {
      inputsToEnable[i].removeAttribute('disabled');
    }
    for (i = 0; i < inputsToDisable.length; i++) {
      inputsToDisable[i].setAttribute('disabled', '');
    }
  }

  function toggle_minimum( typeSelect ) {
    var amountInput = typeSelect.parentNode.parentNode.querySelector('input[name=pris]');
    if (!amountInput) {
      return;
    }
    if (typeSelect.value === 'giver') {
      amountInput.setAttribute('data-min', '100');
      amountInput.dispatchEvent(new Event('blur')); /* revalidate */
    } else {
      amountInput.removeAttribute('data-min');
      amountInput.dispatchEvent(new Event('input')); /* revalidate */
    }
  }

  function toggle_submit_text( typeSelect ) {
    var submit = typeSelect.form.querySelector('[type=submit]');
    var newText = submit.dataset.alternateCaption;
    submit.dataset.alternateCaption = submit.textContent;
    submit.textContent = newText;
  }

  function setup( selector ){
    document.addEventListener('DOMContentLoaded', function() {
      var selects = document.querySelectorAll( selector );

      for ( var i = 0, j = selects.length; i < j; ++i) {
        handle_value_change( selects[i] );
      }
    });
  }

  var module = {};
  module.setup = setup;
  return module;
})();

/* Used for checkboxes that show/hide form fields. The show/hide functionality
 * itself is handled by the Toggle JS module, but we also need to make sure
 * that hidden form fields are disabled, e.g. so that they don't trigger
 * invisible validation errors.
 */
var CtaFormShowHide = (function() {
  function handleChange(event) {
    var input = event.target;
    var fieldContainerId = input.getAttribute('data-target');
    var fieldContainer = document.getElementById(fieldContainerId);
    var fields = fieldContainer.getElementsByTagName('input');
    [].forEach.call(fields, function(field) {
      field.disabled = !input.checked;
    });
  }

  function setup(selector) {
    document.addEventListener('DOMContentLoaded', function() {
      var checkboxes = document.querySelectorAll(selector);
      [].forEach.call(checkboxes, function(checkbox) {
        checkbox.addEventListener('change', handleChange);
      });
    });
  }

  var module = {};
  module.setup = setup;
  return module;
})();

// Initialize the scripts
PurposeSelector.setup( '.cta-box--guardian' );
CustomSelector.setup('.js-custom-amount');
DonationTypeSelector.setup('.js-donation-type');
CtaFormShowHide.setup('.js-show-tax');
CtaFormShowHide.setup('.js-toggle-payer-address');
