/* Set up a custom play/pause button for a carousel.
 * The button will have two states, hereby referred to as "play" and "pause":
 * - "play" when a button click would cause playback to start (i.e. when the
 *   video is paused)
 * - "pause" when a button click would cause playback to pause (i.e. when the
 *   video is playing)
 * Use by creating a button and giving it the class 'js-carousel-control'. Also
 * give it the following data attributes:
 * - data-carousel-id: ID of the <video> element
 * - data-play-id: ID of element (typically, icon/span within the button) which
 *   should be showing in the "play" state and hidden otherwise
 * - data-pause-id: ID of element (typically, icon/span within the button) which
 *   should be showing in the "pause" state and hidden otherwise
 * (The showing/hiding referred to above will be done by setting/removing the
 * "hidden" attribute. Any CSS affecting the elements needs to take care not to
 * override this; when setting "display" it might be necessary to also add a
 * higher-specificity rule setting display:none for [hidden].)
 */
(function() {
  var STATE_PLAY = 'play';
  var STATE_PAUSE = 'pause';

  function getOppositeState(state) {
    return state == STATE_PLAY ? STATE_PAUSE : STATE_PLAY;
  }

  function updateControl(control, newState) {
    var oldState = getOppositeState(newState);
    var idToShow = control.dataset[oldState + 'Id'];
    var idToHide = control.dataset[newState + 'Id'];
    document.getElementById(idToShow).hidden = false;
    document.getElementById(idToHide).hidden = true;
  }

  function updateCarousel(carousel, newState) {
    if (newState == STATE_PLAY) {
      carousel.classList.add('isPlaying');
    } else {
      carousel.classList.remove('isPlaying');
    }
  }

  var controls = document.querySelectorAll('.js-carousel-control');
  [].forEach.call(controls, function(control) {
    var carousel = document.getElementById(control.dataset.carouselId);
    var state = STATE_PLAY;
    control.addEventListener('click', function() {
      state = getOppositeState(state);
      updateCarousel(carousel, state);
      updateControl(control, state);
    });
    control.setAttribute('aria-controls', carousel.id);
    updateCarousel(carousel, state);
    updateControl(control, state);
  });
})();
