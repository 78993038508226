'use strict';

/*
 * Accessible tabs implementation by Heydon Pickering
 * https://inclusive-components.design/tabbed-interfaces/
 * https://codepen.io/heydon/pen/veeaEa
 * Modified from ES6 to ES5 and refactored as a component.
 * Also modified w.r.t. treatment of IDs (fewer assumptions, allow several
 * tab groups per page)
 *
 * Usage: set class 'js-tabs' and attribute 'data-tabs-default' (to zero-based
 * index of tab to be selected by default) on a wrapper. The data attribute may
 * be omitted (defaults to 0). Wrapper must contain the tab panels, each
 * labelled with the 'js-tabs-panel' class and having a unique 'id' attribute.
 * Wrapper must also contain a 'ul' containing 'a' elements representing the
 * tabs. The 'href' of each such 'a' must point to the corresponding tab panel.
 * Each tab ('a') must also have a unique ID attribute.
 */

var Tabs = function () {
  function setupTabGroup(tabbed, defaultTabAttribute) {
    // Get relevant elements and collections
    var tablist = tabbed.querySelector('ul');
    var tabs = tablist.querySelectorAll('a');
    var panels = tabbed.querySelectorAll('.js-tabs-panel');
    var defaultTab = defaultTabAttribute && tabbed.getAttribute(defaultTabAttribute) ? tabbed.getAttribute(defaultTabAttribute) : 0;

    // The tab switching function
    var switchTab = function switchTab(oldTab, newTab) {
      newTab.focus();
      // Make the active tab focusable by the user (Tab key)
      newTab.removeAttribute('tabindex');
      // Set the selected state
      newTab.setAttribute('aria-selected', 'true');
      oldTab.removeAttribute('aria-selected');
      oldTab.setAttribute('tabindex', '-1');
      // Get the indices of the new and old tabs to find the correct
      // tab panels to show and hide
      var index = Array.prototype.indexOf.call(tabs, newTab);
      var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
      panels[oldIndex].hidden = true;
      panels[index].hidden = false;
    };

    // Add the tablist role to the first <ul> in the .tabbed container
    tablist.setAttribute('role', 'tablist');

    // Add semantics are remove user focusability for each tab
    Array.prototype.forEach.call(tabs, function (tab, i) {
      tab.setAttribute('role', 'tab');
      tab.setAttribute('tabindex', '-1');
      tab.parentNode.setAttribute('role', 'presentation');

      // Handle clicking of tabs for mouse users
      tab.addEventListener('click', function (e) {
        e.preventDefault();
        var currentTab = tablist.querySelector('[aria-selected]');
        if (e.currentTarget !== currentTab) {
          switchTab(currentTab, e.currentTarget);
        }
      });

      // Handle keydown events for keyboard users
      tab.addEventListener('keydown', function (e) {
        // Get the index of the current tab in the tabs node list
        var index = Array.prototype.indexOf.call(tabs, e.currentTarget);
        // Work out which key the user is pressing and
        // Calculate the new tab's index where appropriate
        var dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
        if (dir !== null) {
          e.preventDefault();
          // If the down key is pressed, move focus to the open panel,
          // otherwise switch to the adjacent tab
          dir === 'down' ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
        }
      });
    });

    // Add tab panel semantics and hide them all
    Array.prototype.forEach.call(panels, function (panel, i) {
      panel.setAttribute('role', 'tabpanel');
      panel.setAttribute('tabindex', '-1');
      var id = panel.getAttribute('id');
      panel.setAttribute('aria-labelledby', tabs[i].id);
      panel.hidden = true;
    });

    // Initially activate the default or first tab and reveal the tab panel
    tabs[defaultTab].removeAttribute('tabindex');
    tabs[defaultTab].setAttribute('aria-selected', 'true');
    panels[defaultTab].hidden = false;
  }

  function setup(selector, defaultTabAttribute) {
    var tabGroups = document.querySelectorAll(selector);
    Array.prototype.forEach.call(tabGroups, function (tabGroup) {
      setupTabGroup(tabGroup, defaultTabAttribute);
    });
  }

  var module = {};
  module.setup = setup;
  return module;
}();

Tabs.setup('.js-tabs', 'data-tabs-default');
