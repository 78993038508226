/* Used e.g. for gift forms, guardian forms and member forms to be able to
 * report errors without reloading the page.
 * Usage:
 * - Give the form the 'js-redirecting-form' class
 * - Ensure that the form contains an element with class
 *   'js-message-placeholder', which will hold any error messages that appear.
 *   This element should be suited to contain <p> (it should be e.g. a div).
 * - There must be exactly one submit button, with the attribute
 *   'data-redirecting-form-submit'.
 */
(function() {
  var forms = document.querySelectorAll('.js-donor-form, .js-member-form, ' +
      '.js-redirecting-form');
  [].forEach.call(forms, function(form) {
    var errorContainer = form.querySelector('.js-message-placeholder');
    var submitButton = form.querySelector('[data-redirecting-form-submit]');

    form.addEventListener('submit', function(event) {
      if (!FormData) {
        return;
      }
      submitButton.disabled = true;

      event.preventDefault();
      var formData = getFormDataWithFallbacks(event.submitter);

      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function() {
        handleResponse.call(this, submitButton);
      });
      xhr.open(form.method, location.href); /* IE11 doesn't handle URL '' */
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.send(formData);
    });

    /* Fills in payer address for panda form if submitter hasn't indicated
     * that their address differs from that of the child/member.
     */
    function getFormDataWithFallbacks(submitter) {
      var formData = new FormData(form);
      if (submitter) {
        formData.append(submitter.name, submitter.value);
      }

      var payerCheckbox = form.querySelector('.js-toggle-payer-address');
      if (payerCheckbox && !payerCheckbox.checked) {
        var fieldsToFill = ['a_adresse2', 'a_postnummer', 'a_poststed'];
        fieldsToFill.forEach(function(inputName) {
          var nameOfFallbackField = inputName + '1';
          var fallbackField = form[nameOfFallbackField];
          if (fallbackField) {
            /* Poor support for formData.set() */
            formData.append(inputName, fallbackField.value);
          }
        });
      }

      return formData;
    }

    function handleResponse(submitButton) {
      try {
        var responseObject = JSON.parse(this.responseText);
        if (responseObject.errors) {
          showErrors(responseObject.errors);
          submitButton.disabled = false;
        } else if (responseObject.redirect) {
          DonationTracking.doSubmitTracking(form);
          location = responseObject.redirect;
        }
      } catch (e) {
        // TODO when can this happen? Show some sort of error?
        submitButton.disabled = false;
      }
    }

    function showErrors(errors) {
      while (errorContainer.firstChild) {
        /* Remove any errors previously shown */
        errorContainer.removeChild(errorContainer.firstChild);
      }
      [].forEach.call(errors, function(errorText) {
        var errorParagraph = document.createElement('p');
        errorParagraph.textContent = errorText;
        errorContainer.appendChild(errorParagraph);
      });
      errorContainer.removeAttribute('hidden');
    }
  });
})();
