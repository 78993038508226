/* Set up lookup of postal place given postal code.
 * Usage: Give an input field the 'data-postal-place-id' attribute, set to the
 * ID of some element (e.g. a div) that will receive the result as its
 * contents. The lookup will trigger on input.
 * Assumes that all postal codes are 4 characters.
 */
var PostalPlace = (function() {
  function fetchPostalPlace(postalCode, callback) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', function() {
      var result = null;
      try {
        result = JSON.parse(xhr.responseText).postalPlace;
      } catch (e) {
      }
      callback(result);
    });
    xhr.open('GET', '/actions/norwegian-postal-codes/lookup?postalCode=' +
        encodeURIComponent(postalCode));
    xhr.send();
  }

  function setup(selector) {
    document.addEventListener('DOMContentLoaded', function() {
      var postalCodeFields = document.querySelectorAll(selector);

      [].forEach.call(postalCodeFields, function(field) {

        var outputElementId = field.dataset.postalPlaceId;
        var outputFieldId = field.dataset.postalPlaceFieldId;

        var outputElement = document.getElementById(outputElementId);
        var outputField = false;

        if( typeof outputFieldId !== "undefined" ) {
          var outputField = document.getElementById(outputFieldId);
        }

        field.addEventListener('input', function() {
          var inputValue = field.value;
          if (inputValue.length > 3) {
            fetchPostalPlace(inputValue, function(result) {
              outputElement.textContent = result || '';
              if( outputField !== false ) {
                outputField.value = result || '';
              }
            });
          } else {
            outputElement.textContent = '';
            if( outputField !== false ) {
              outputField.value = '';
            }
          }
        });

      });

    });
  }

  var module = {};
  module.setup = setup;
  return module;
})();

PostalPlace.setup('[data-postal-place-id]');
