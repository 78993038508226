/* Used to add tracking (requested by 2xl) to various donation/member forms
 */
(function() {
  var donorFormContinueButtons = document.querySelectorAll(
      '.js-donor-form .js-show-form, .js-show-card-info');
  var memberForms = document.querySelectorAll('.js-member-form');

  var csrfField = formUrlEncoded(window.Csrf.name) + '=' +
      formUrlEncoded(window.Csrf.value);

  [].forEach.call(donorFormContinueButtons, function(button) {
    button.addEventListener('click', doCheckoutTracking);
  });
  [].forEach.call(memberForms, function(form) {
    form.addEventListener('submit', doCheckoutTracking);
  });

  function formUrlEncoded(str) {
    return encodeURIComponent(str).replace(/%20/g, '+');
  }

  function triggerFacebookServerSideEvent(eventName, eventId) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/actions/facebook-conversion/event');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send('name=' + eventName + '&id=' + eventId + '&' + csrfField);
  }

  function doCheckoutTracking() {
    var eventName = 'InitiateCheckout';
    var eventId = String(Date.now()) + Math.floor(Math.random() * 10000);
    window.dataLayer.push({
      event: eventName,
      eventId: eventId,
    });
    triggerFacebookServerSideEvent(eventName, eventId);
  }

  function doSubmitTracking(form) {
    if (!form.classList.contains('js-donor-form')) {
      return;
    }
    var fields = form.elements;
    var purposeCode = fields['prosjekt-produkt'] && fields['prosjekt-produkt'].value;
    var isGuardianSignup = (fields.gi_type && fields.gi_type.value === 'giver') ||
        purposeCode === 'FF-300';
    var transactionId = fields.transaction && fields.transaction.value;
    var amount = (fields.pris && Number(fields.pris.value)) || 0;
    var seedPacketCount = form.querySelector('.js-seed-packet-count');
    if (seedPacketCount) {
      purposeCode = 'WG-1000-' + seedPacketCount.value;
      amount = form.querySelector('.js-seed-price').textContent;
    } else if (fields.action &&
        fields.action.value === 'wwf-donor/donate/gifted-guardianship') {
      purposeCode = 'FG-1500';
      isGuardianSignup = true;
    }
    if (!purposeCode || !transactionId) {
      return;
    }

    window.dataLayer.push({
      event: 'Purchase',
      eventId: transactionId,
      donationAmount: amount,
      purposeCode: purposeCode,
      purposeTitle: null,
      donationType: isGuardianSignup ? 'Monthly' : 'Single',
    });
  }

  window.DonationTracking = {
    doSubmitTracking: doSubmitTracking
  };
})();
