var PetitionForm = (function() {
  var letterDialog;
  var signatureDialog;
  var signatureDialogElement;

  function setup(element) {
    if (!letterDialog) {
      var letterDialogId = element.dataset.petitionLetterDialog;
      if (letterDialogId) {
        letterDialog =
            new A11yDialog(document.getElementById(letterDialogId), 'main');
      }
    }
    if (!signatureDialog) {
      var signatureDialogId = element.dataset.signatureShareDialog;
      signatureDialogElement = document.getElementById(signatureDialogId);
      signatureDialog =
          new A11yDialog(signatureDialogElement, 'main');

      var signatureForm = signatureDialogElement.querySelector('form');
      var receiptUrl = signatureForm.dataset.receiptUrl;

      InlineFormReceipt.setupElement(signatureForm, function() {
        if (receiptUrl) {
          window.location = receiptUrl;
        } else {
          signatureDialog.hide();
        }
      });
    }
    InlineFormReceipt.setupElement(element, function() {
      if (letterDialog) {
        letterDialog.hide();
      }
      var submittedFirstName = element.a_fornavn.value;
      signatureDialogElement.querySelector('.js-signature-name-preview')
          .textContent = submittedFirstName;
      signatureDialog.show();
    });
  }

  var module = {};
  module.setup = setup;
  return module;
})();

/* Waiting for 'load' to ensure InlineFormReceipt is available */
window.addEventListener('load', function() {
  [].forEach.call(document.querySelectorAll('[data-signature-share-dialog]'),
      PetitionForm.setup);
});
