/* Set up a custom play/pause button for a native video element.
 * The button will have two states, hereby referred to as "play" and "pause":
 * - "play" when a button click would cause playback to start (i.e. when the
 *   video is paused)
 * - "pause" when a button click would cause playback to pause (i.e. when the
 *   video is playing)
 * Use by creating a button and giving it the class 'js-video-control'. Also
 * give it the following data attributes:
 * - data-video-id: ID of the <video> element
 * - data-play-text: Label text to use in the "play" state
 * - data-pause-text: Label text to use in the "pause" state
 * - data-play-id: ID of element (typically, icon within the button) which
 *   should be showing in the "play" state and hidden otherwise
 * - data-pause-id: ID of element (typically, icon within the button) which
 *   should be showing in the "pause" state and hidden otherwise
 * (The showing/hiding referred to above will be done by setting/removing the
 * "hidden" attribute. Any CSS affecting the elements needs to take care not to
 * override this; when setting "display" it might be necessary to also add a
 * higher-specificity rule setting display:none for [hidden].)
 *
 * The script will hide user agent controls for the video. In this way, default
 * controls can be left on for fallback purposes (no/failing JS).
 */
(function() {
  var STATE_PLAY = 'play';
  var STATE_PAUSE = 'pause';

  function updateState(control, video) {
    if (video.paused) {
      setState(control, STATE_PLAY);
    } else {
      setState(control, STATE_PAUSE);
    }
  }

  function setState(control, state) {
    var label = control.dataset[state + 'Text'];
    control.title = label;
    control.setAttribute('aria-label', label);
    var oppositeState = state == STATE_PLAY ? STATE_PAUSE : STATE_PLAY;
    var idToShow = control.dataset[state + 'Id'];
    var idToHide = control.dataset[oppositeState + 'Id'];
    document.getElementById(idToShow).hidden = false;
    document.getElementById(idToHide).hidden = true;
  }

  function togglePause(control, video) {
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  var controls = document.querySelectorAll('.js-video-control');
  [].forEach.call(controls, function(control) {
    var video = document.getElementById(control.dataset.videoId);
    video.addEventListener('play', function() {
      setState(control, STATE_PAUSE);
    });
    video.addEventListener('pause', function() {
      setState(control, STATE_PLAY);
    });
    control.addEventListener('click', function() {
      togglePause(control, video);
    });
    updateState(control, video);
    video.controls = false;
  });
})();
