/* Implements functionality for collapsing a list to 3 items and expanding it
 * on the click on a button.
 *
 * To use: add a button with class 'js-expand-list' as a sibling directly
 * following the list to be collapsed, and give the list an ID. The button
 * should have a 'hidden' attribute.
 *
 * The child elements of the list must only be <li> elements (e.g. no <script>)
 */
(function() {
  function setCollapseState(list, shouldCollapse) {
    list.setAttribute('aria-expanded', !shouldCollapse);
    for (var i = 3; i < list.children.length; i++) {
      list.children[i].hidden = shouldCollapse;
    }
  }

  var buttons = document.querySelectorAll('.js-expand-list');
  [].forEach.call(buttons, function(button) {
    var list = button.previousElementSibling;
    button.setAttribute('aria-controls', list.id);
    button.removeAttribute('hidden');
    setCollapseState(list, true);
    button.addEventListener('click', function() {
      button.hidden = true;
      setCollapseState(list, false);
    });
  });
})();
