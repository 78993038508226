(function() {
  function setupGallery(gallery) {
    var dialogElement =
        document.getElementById(gallery.dataset.galleryDialog);
    var dialog = new window.A11yDialog(dialogElement, '#page');
    var carousel = dialogElement.querySelector('.glide');
    var glide = new Glide(carousel, {
      peek: 100,
      breakpoints: {
        600: {
          peek: 0
        }
      }
    });
    dialog.on('show', function() {
      glide.mount();
      glide.enable();
    });
    dialog.on('hide', function() {
      glide.disable();
    });
    setupTriggers(gallery, dialog, glide);
  }

  function setupTriggers(gallery, dialog, glide) {
    var triggers = gallery.querySelectorAll('.js-gallery-item');
    [].forEach.call(triggers, function(trigger) {
      var galleryIndex = trigger.dataset.galleryIndex;
      trigger.addEventListener('click', function() {
        glide.update({startAt: galleryIndex});
        dialog.show();
      });
    });
  }

  window.addEventListener('DOMContentLoaded', function() {
    var galleries = document.querySelectorAll('.js-gallery');
    [].forEach.call(galleries, setupGallery);
  });
})();
