/* Sets up search field to be shown in a collapsed form, and expanded at the
 * click of a button.
 *
 * Usage:
 * On the button that should trigger the expanding, set the following:
 * - the class 'js-search-collapse'
 * - the 'hidden' attribute (so the form can work normally if JS is disabled)
 * - the 'data-field' attribute, to the ID of the search field
 * - the 'data-submit' attribute, to the ID of the submit button
 * The search field and submit button must be located within a 'form' element.
 *
 * Upon setup, but before trigger button is clicked:
 * - the 'isCollapsed' class is set on the form
 * - 'disabled' state is set on the search field and submit button
 * - 'hidden' state is removed from the trigger button
 *
 * When the trigger button is clicked:
 * - the 'isCollapsed' class is removed from the form
 * - 'disabled' state is removed from the search field and submit button
 * - 'hidden' state is set on the trigger button
 * - the search field is focused
 */
(function() {
  var COLLAPSE_CLASS = 'isCollapsed';

  function setup(trigger) {
    var searchField = document.getElementById(trigger.dataset.field);
    var submitButton = document.getElementById(trigger.dataset.submit);
    var searchForm = searchField.form;

    searchField.disabled = true;
    submitButton.disabled = true;
    searchForm.classList.add(COLLAPSE_CLASS);
    trigger.hidden = false;

    trigger.addEventListener('click', function () {
      searchField.disabled = false;
      submitButton.disabled = false;
      trigger.hidden = true;
      searchField.focus();
      searchForm.classList.remove(COLLAPSE_CLASS);
    });
  }

  var triggers = document.querySelectorAll('.js-search-collapse');
  [].forEach.call(triggers, setup);
})();
