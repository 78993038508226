/* Sets up custom validation errors for all specified form fields.
 * - The input field must be placed within a wrapper with class js-validate
 *   (only one input field per wrapper is supported).
 * - The wrapper will receive a 'form-field--error' class when invalid
 *   (this invalid state is normally only enabled on submit and only disabled
 *   on input).
 * - The input field must have an ID, and it must be possible to use it as a
 *   prefix for new IDs without risking collisions.
 * - When the field is invalid, a paragraph with class 'form-field__error' is
 *   appended to the wrapper.
 * - The input field must be located within a form that contains an element
 *   with class 'js-message-placeholder' to which it is possible to add
 *   paragraphs (<p> elements).
 * - The actual error messages for these paragraphs are retrieved from the
 *   Strings global (which is a dependency).
 *
 * If an input field of the type described above has a 'data-min' attribute,
 * it will be treated as invalid if its numerical value is less than the
 * 'data-min' attribute's value (assumed to be an integer).
 *
 * If the form contains an element with class 'js-validate-form-error-summary',
 * its contents will be used as a generic error summary (to be added to the
 * js-message-placeholder element). Else, Strings.formValidationSummary is
 * used.
 */
var ValidateField = (function() {
  ERROR_ID_SUFFIX = '-error';
  WRAPPER_ERROR_CLASS = 'form-field--error';
  ERROR_SUMMARY_SELECTOR = '.js-message-placeholder';

  function markAsInvalid(wrapper, input) {
    var paragraph;
    var errorId = input.id + ERROR_ID_SUFFIX;
    if (wrapper.classList.contains(WRAPPER_ERROR_CLASS)) {
      paragraph = document.getElementById(errorId);
    } else {
      paragraph = document.createElement('p');
      paragraph.id = errorId;
      paragraph.className = 'form-field__error';
      paragraph.setAttribute('role', 'alert');
    }
    wrapper.classList.add(WRAPPER_ERROR_CLASS);
    var errorMessage = getErrorMessage(input);
    paragraph.textContent = errorMessage;
    wrapper.appendChild(paragraph);
    input.setAttribute('aria-describedby', errorId);
  }

  function markAsValid(wrapper, input) {
    if (!wrapper.classList.contains(WRAPPER_ERROR_CLASS)) {
      return;
    }
    input.removeAttribute('aria-describedby');
    var errorId = input.id + ERROR_ID_SUFFIX;
    var paragraph = document.getElementById(errorId);
    paragraph.parentNode.removeChild(paragraph);
    wrapper.classList.remove(WRAPPER_ERROR_CLASS);
  }

  function getErrorMessage(input) {
    if (input.hasAttribute('data-min')) {
      return Strings.formValidationRepeatedSumTooLow
          .replace('{x}', input.dataset.min);
    }
    if (input.validity.valid) {
      return '';
    }
    if (input.validity.valueMissing) {
      return Strings.formValidationMissing;
    }
    if (input.value < input.min) {
      return Strings.formValidationMin.replace('{x}', input.min);
    }
    if (input.value > input.max) {
      return Strings.formValidationMax.replace('{x}', input.max);
    }
    return Strings.formValidationInvalid;
  }

  function showErrorSummary(form) {
    var errorContainer = form.querySelector(ERROR_SUMMARY_SELECTOR);
    if (!errorContainer.hidden) {
      return;
    }
    var errorParagraph =
        form.querySelector('.js-validate-form-error-summary');
    if (errorParagraph) {
      errorParagraph = errorParagraph.cloneNode(true)
      errorParagraph.hidden = false;
    } else {
      errorParagraph = document.createElement('p');
      errorParagraph.textContent = Strings.formValidationSummary;
    }
    errorContainer.appendChild(errorParagraph);
    errorContainer.hidden = false;
  }

  function validateCustomMinimum(input) {
    if (!input.hasAttribute('data-min')) {
      return true;
    }
    var customMinimum = parseInt(input.dataset.min);
    return input.value >= customMinimum;
  }

  function setup(selector) {
    var fieldWrappers = document.querySelectorAll(selector);
    [].forEach.call(fieldWrappers, setupWrapper);
  }

  function setupWrapper(fieldWrapper) {
    var input = fieldWrapper.querySelector('input, textarea');
    input.addEventListener('invalid', function(e) {
      markAsInvalid(fieldWrapper, input);
      showErrorSummary(input.form);
      e.preventDefault();
    });
    input.addEventListener('input', function() {
      var customMinimumIsValid = validateCustomMinimum(input);
      if (input.validity.valid && customMinimumIsValid) {
        markAsValid(fieldWrapper, input);
      }
    });
    input.addEventListener('blur', function() {
      if (!validateCustomMinimum(input)) {
        markAsInvalid(fieldWrapper, input);
      }
    });
  }

  var module = {}
  module.setup = setup;
  module.setupWrapper = setupWrapper;
  return module;
})();

ValidateField.setup('.js-validate');
