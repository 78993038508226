(function() {
  /* Enable fields for customer info if user selected to pay by card */
  var cardToggleButtons = document.querySelectorAll('.js-show-card-info');
  [].forEach.call(cardToggleButtons, function(button) {
    button.addEventListener('click', function() {
      document.getElementById(button.dataset.target).disabled = false;
    });
  });

  /* Preview price for seed packets */
  function handleSeedPacketChange(select, output) {
    var price = (select.value - 1) * 50 + 100;
    output.textContent = price;
  }

  var seedCountSelects = document.querySelectorAll('.js-seed-packet-count');
  [].forEach.call(seedCountSelects, function(select) {
    var output = select.form.querySelector('.js-seed-price');
    select.addEventListener('change', function() {
      handleSeedPacketChange(select, output);
    });
    handleSeedPacketChange(select, output);
  });
})();
