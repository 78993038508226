/* Sets up functionality for adding/removing family members in guardian family
 * form
 */
(function() {
  var addButtons =
      document.querySelectorAll('[data-guardian-family-add-member]');
  var copiesMade = 0;
  var idMatcher = /(member)(\d+)/;
  var nameMatcher = /(mottakere\[)(\d+)/;

  function increaseMemberCounterInAttribute(element, attribute, matcher) {
    if (!element[attribute]) {
      return;
    }
    element[attribute] = element[attribute].replace(matcher,
      function(_, prefix, index) {
        return prefix + (Number(index) + copiesMade);
      }
    );
  }

  [].forEach.call(addButtons, function(button) {
    var template =
        document.getElementById(button.dataset.guardianFamilyAddMember);
    button.addEventListener('click', function() {
      var newMember = template.cloneNode(true);
      copiesMade++;

      [].forEach.call(newMember.querySelectorAll('[id]'), function(element) {
        increaseMemberCounterInAttribute(element, 'id', idMatcher);
      });
      [].forEach.call(newMember.querySelectorAll('[for]'), function(element) {
        increaseMemberCounterInAttribute(element, 'htmlFor', idMatcher);
      });
      [].forEach.call(newMember.querySelectorAll('[name]'), function(element) {
        increaseMemberCounterInAttribute(element, 'name', nameMatcher);
      });
      [].forEach.call(newMember.querySelectorAll('input'), function(element) {
        element.value = '';
      });

      var deleteButton =
          newMember.querySelector('[data-guardian-family-remove-member]');
      deleteButton.addEventListener('click', function() {
        newMember.parentNode.removeChild(newMember);
        /* Not decreasing copiesMade again, because the largest index will
         * still be "taken" if the removed member wasn't the last one
         */
        button.focus();
      });
      deleteButton.hidden = false;

      ValidateField.setupWrapper(newMember);

      template.parentNode.insertBefore(newMember, button.parentNode);
    });
  });
})();
