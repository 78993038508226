/* Set up a form so that it is submitted via XHR and shows success/error
 * message "inline".
 *
 * The XHR is sent to the current URL, and expects to receive HTTP response
 * code 200 if the submission succeeds; otherwise an error message is shown. By
 * default it is a generic message, but this can be customized by returning a
 * JSON object with an "errorSummary" string field. E.g.:
 * {"errorSummary": "A more specific error message"}
 *
 * The form is expected to contain only one <button> element (further assumed
 * to be a submit button). This will be disabled while waiting for the XHR
 * response.
 *
 * Upon successful submission, the message given by the form's
 * 'data-inline-form-receipt-text' attribute will replace the form itself.
 * If there is no such attribute, no such replacement is done.
 *
 * For the error message shown in other cases, the form must contain an element
 * with class 'js-message-placeholder'. The error will be set as the content of
 * this element.
 *
 * Usage: InlineFormReceipt.setup(selector) where 'selector' is a (CSS)
 * selector matching each of the forms to be affected.
 *
 * Alternatively: InlineFormReceipt.setupElement(form, callback) where 'form'
 * is the DOM element to be affected and 'callback' (optional) is a function
 * that will be called after successful submit (and after the inline success
 * message is displayed)
 */
var InlineFormReceipt = (function() {
  function setup(selector) {
    var forms = document.querySelectorAll(selector);
    [].forEach.call(forms, setupElement);
  }

  function setupElement(form, callback) {
    var submitButton = form.querySelector('button');
    form.addEventListener('submit', function(event) {
      if (!FormData) {
        return;
      }
      event.preventDefault();
      submitButton.disabled = true;
      var formData = new FormData(form);
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function() {
        var isOk = this.status === 200;
        handleResponse(form, this.response, submitButton, isOk);
        if (callback && isOk) {
          callback();
        }
      });
      xhr.open(form.method, location.href);
      xhr.responseType = 'json';
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.send(formData);
    });
  }

  function handleResponse(form, response, submitButton, isOk) {
    if (isOk) {
      var receiptText = form.dataset.inlineFormReceiptText;
      if (!receiptText) {
        submitButton.disabled = false;
        return;
      }
      var receipt = createReceiptElement(receiptText);
      form.parentNode.replaceChild(receipt, form);
    } else {
      submitButton.disabled = false;
      console.warn(JSON.stringify(response));
      var errorContainer = form.querySelector('.js-message-placeholder');
      var errorMessage = response.errorSummary || Strings.formValidationSummary;
      errorContainer.textContent = errorMessage;
      errorContainer.removeAttribute('hidden');
    }
  }

  /* This function implementation is project-specific (in terms of markup/CSS
   * classes).
   */
  function createReceiptElement(receiptText) {
    var receipt = document.createElement('div');
    receipt.setAttribute('role', 'alert');
    receipt.className = 'message';
    receipt.appendChild(document.createTextNode(receiptText));
    return receipt;
  }

  var module = {};
  module.setup = setup;
  module.setupElement = setupElement;
  return module;
})();

InlineFormReceipt.setup('.js-inline-form-receipt');
