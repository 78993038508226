/* Used for support member forms, to toggle between the "become a member" and
 * "give membership as gift" modes
 */
(function() {
  var TYPE_GIFT = 'gift';
  var TYPE_SELF = 'self';

  var forms = document.querySelectorAll('.js-member-form');
  [].forEach.call(forms, function(form) {
    var radioGroup = form.querySelector('.js-type-radios');

    if (!radioGroup) {
      return;
    }

    radioGroup.addEventListener('change', function(event) {
      var newState = event.target.value;
      handleChange(newState, form);
    });

    handleChange(TYPE_SELF, form);
  });

  function handleChange(newState, form) {
    switchTitle(newState, form);
    toggleOnOff(newState, form);
  }

  function switchTitle(newState, form) {
    var heading = form.querySelector('[data-gift-title]');
    if (!heading.dataset.selfTitle) {
      heading.dataset.selfTitle = heading.textContent;
    }
    heading.textContent = heading.dataset[newState + 'Title'];
  }

  function toggleOnOff(newState, form) {
    var oldState = newState == TYPE_GIFT ? TYPE_SELF : TYPE_GIFT;
    var elementsToToggle = form.querySelectorAll('[data-member-type]');

    [].forEach.call(elementsToToggle, function(element) {
      var shouldToggleOff = element.dataset.memberType == oldState;
      element.hidden = shouldToggleOff;
      if (element.tagName === 'FIELDSET') {
        element.disabled = shouldToggleOff;
      }
    });
  }
})();
