/* Animate number by counting from 0 up to the actual value.
 * Usage: on the element that will hold the animating number itself, set the
 * "data-animate-counter-to" attribute to the final number.
 * The element may also be given the "hidden" attribute for noscript purposes.
 * It will be un-hidden before the animation starts.
 */
(function() {
  var counters = document.querySelectorAll('[data-animate-counter-to]');
  var options = {
    startVal: 0,
    duration: 3,
    separator: ' ',
    enableScrollSpy: true,
    scrollSpyOnce: true
  };

  window.addEventListener('DOMContentLoaded', function() {
    [].forEach.call(counters, function(counter) {
      counter.hidden = false;
      var animation = new countUp.CountUp(counter,
          counter.dataset.animateCounterTo, options);
      animation.start();
    });
  });
})();
