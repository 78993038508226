(function() {
  if (!("IntersectionObserver" in window)) {
    return;
  }

  var stories = document.querySelectorAll('.js-story');
  var cards = document.querySelectorAll('.js-story-card');

  var observer = new IntersectionObserver(function(entries, observer) {
    entries.forEach(function(entry) {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-active');
      } else {
        entry.target.classList.remove('is-active');
      }
    });
  }, { rootMargin: "0% 0% -99% 0%" });

  [].forEach.call(cards, function(card) {
    observer.observe(card);
  });
  [].forEach.call(stories, function(story) {
    story.classList.add('is-story-scripted');
  });
})();
