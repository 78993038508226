/* Set up a select dropdown to show an associated longer text (a "preview") for
 * the selected option, for cases where the option values are brief texts so
 * that they can fit on one line even for narrower screens, but where it's
 * still useful to show a longer, multi-line text that more completely
 * describes the option.
 *
 * The functionality is triggered by setting the class 'js-option-preview' on
 * the relevant select element. The select must also have the attribute
 * 'data-option-preview-out', set to the ID of the element that is to contain
 * the preview.
 *
 * Said output element may contain a hardcoded prefix text, shared among all
 * the previews The element must furthermore contain an empty 'span' element,
 * to hold the postfix that will vary depending on the option selected.
 *
 * Each option in the select must have 'data-option-preview-postfix', holding
 * the postfix contents that will be inserted into the aforementioned span.
 * NOTE: this value will be interpreted as HTML markup!
 *
 * The output element's parent may have the 'hidden' attribute set, which will
 * be removed by this script on initialization (this is to handle behavior in
 * the case of disabled JS, where this dynamic preview functionality won't
 * work).
 *
 * The select element will receive an 'aria-describedby' pointing to the output
 * element.
 */
(function() {
  var selects = document.querySelectorAll('.js-option-preview');

  [].forEach.call(selects, function(select) {
    var fullPreviewId = select.dataset.optionPreviewOut;
    var fullPreview = document.getElementById(fullPreviewId);
    var output = fullPreview.querySelector('span');

    function updatePostfix() {
      if (select.selectedIndex < 0) {
        return;
      }
      var selectedOption = select.options[select.selectedIndex];
      var selectedPostfix = selectedOption.dataset.optionPreviewPostfix;
      output.innerHTML = selectedPostfix;
    }

    select.addEventListener('change', updatePostfix);
    updatePostfix();

    fullPreview.parentNode.removeAttribute('hidden');
    select.setAttribute('aria-describedby', fullPreviewId);
  });
})();
