var DidYouFind = (function() {
  function addXhrSubmitForButton(button, form, callback) {
    button.addEventListener('click', function(event) {
      if (!FormData) {
        return;
      }
      event.preventDefault();
      button.disabled = true;

      var formData = new FormData(form);
      formData.append(button.name, button.value);

      var xhr = new XMLHttpRequest();
      if (callback) {
        xhr.addEventListener('load', callback);
      }
      xhr.open(form.method, location.href);
      xhr.responseType = 'json';
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.send(formData);
    });
  }

  function setup(selector) {
    var forms = document.querySelectorAll(selector);
    [].forEach.call(forms, function(form) {
      var yesButton = document.getElementById(form.dataset.yesButton);
      var noButton = document.getElementById(form.dataset.noButton);
      var feedbackButton =
          document.getElementById(form.dataset.feedbackButton);
      addXhrSubmitForButton(yesButton, form, null);
      noButton.type = 'button'; /* Disable no-JS fallback submit behavior */
      addXhrSubmitForButton(feedbackButton, form, null);
    });
  }

  var module = {};
  module.setup = setup;
  return module;
})();

DidYouFind.setup('.js-did-you-find');
